<template>
  <div>
    <!-- Filters -->
    <!--
    <list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :projects="projects"
      :plan-options="planOptions"
      @refetch-data="refetchData"
      :isEdit="isEdit"
      :currentData="currentData"
    />

   <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="projects"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(campaign_status)="data">
          <b-spinner
            v-if="data.value == 'active'"
            :variant="'success'"
            small
            type="grow"
          />
          <b-icon v-else variant="danger" scale="1" icon="circle-fill"></b-icon>
        </template>
        <template #cell(project)="data">
          {{
            projects.find((e) => e.internal_name == data.item.project)
              ? projects.find((e) => e.internal_name == data.item.project)
                  .display_name
              : data.item.project
          }}
        </template>
        <template #cell(filter)="data">
          {{
            data.item.filter.map((datum) => {
              return `${datum.column} ${datum.operand} ${datum.value}`;
            })
          }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="StartCampaign(data.item)">
              <feather-icon icon="PlayIcon" />
              <span class="align-middle ml-50">{{ lang("t_start") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="StopCampaign(data.item)">
              <feather-icon icon="StopCircleIcon" />
              <span class="align-middle ml-50">{{ lang("t_stop") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="RecycleCampaign(data.item)">
              <feather-icon icon="RefreshCwIcon" />
              <span class="align-middle ml-50">{{ lang("t_recycle") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ dataMeta.of }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      size="lg"
      scrollable
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          style="min-height: 60vh; overflow-y: auto"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Project -->
          <b-row>
            <b-col cols="5">
              <validation-provider
                #default="validationContext"
                name="Project"
                rules="required"
              >
                <b-form-group :label="lang('t_project')" label-for="project">
                  <v-select
                    v-model="currentData.project"
                    :options="projects"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    @input="get_columns"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_displayName')"
                  label-for="display_name"
                >
                  <b-form-input
                    id="display_name"
                    v-model="currentData.display_name"
                    autofocus
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="1">
              <b-button
                block
                variant="outline-primary"
                class="btn-icon mt-2"
                @click="repeateAgain"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.bottom="lang('t_addFilter')"
              >
                <feather-icon class="mr-1" icon="PlusIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-form-radio
              class="ml-1 mb-1"
              v-model="currentData.mode"
              name="dialer"
              value="dialer"
            >
              {{ lang("t_algorithmicDialer") }}
            </b-form-radio>
            <b-form-radio
              class="ml-1 mb-1"
              v-model="currentData.mode"
              name="power"
              value="power"
            >
              {{ lang("t_basicDialer") }}
            </b-form-radio>
          </b-row>
          <b-row>
            <b-form-checkbox
              class="mb-1 ml-1"
              v-model="currentData.auto_recycle"
            >
              {{ lang("Auto Recycle") }}
            </b-form-checkbox>
          </b-row>

          <div>
            <div>
              <h4>{{ lang("t_filters") }}</h4>
              <b-form
                style="max-height: 20vh; overfilow-y: auto"
                ref="form"
                :style="{ height: trHeight }"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in currentData.filter"
                  :id="item.id"
                  :key="index"
                  ref="row"
                >
                  <!-- Item Name -->
                  <b-col md="4" sm="6">
                    <b-form-group
                      :label="lang('t_columnName')"
                      label-for="profile"
                    >
                      <v-select
                        label="display_name"
                        :reduce="(val) => val.internal_name"
                        :options="columns"
                        v-model="item.column"
                      >
                        <!-- <template slot="option" slot-scope="option">
                          <span>{{
                            column_labels.hasOwnProperty(option.label)
                              ? column_labels[option.label]
                              : option.label
                          }}</span>
                        </template> -->
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <!-- Cost -->
                  <b-col md="4" sm="6">
                    <b-form-group
                      :label="lang('t_operator')"
                      label-for="t_operator"
                    >
                      <v-select
                        label="display_name"
                        :reduce="(val) => val.internal_name"
                        :options="operands"
                        v-model="item.operand"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Quantity -->
                  <b-col md="3" sm="6">
                    <b-form-group
                      :label="lang('t_value')"
                      label-for="t_value"
                      v-if="item.column == 'Kuyruk'"
                    >
                      <v-select
                        :options="queues"
                        v-model="item.value"
                        label="display_name"
                        :reduce="(val) => val.internal_name"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="lang('t_value')"
                      label-for="t_value"
                      v-if="item.column && item.column.includes('_date')"
                    >
                      <flat-pickr
                        v-model="item.value"
                        class="form-control"
                        :config="{
                          enableTime: false,
                          dateFormat: 'Y-m-d',
                          allowInput: true,
                          time_24hr: true,
                        }"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="lang('t_value')"
                      label-for="t_value"
                      v-if="item.column == 'finish_code'"
                    >
                      <v-select
                        :options="finish_codes"
                        v-model="item.value"
                        label="finish_code"
                        :reduce="(val) => val.finish_code"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="
                        item.column &&
                        !item.column.includes('_date') &&
                        !['Kuyruk', 'finish_code'].includes(item.column)
                      "
                      :label="lang('t_value')"
                      label-for="t_value"
                    >
                      <b-form-input
                        autofocus
                        id="t_value"
                        v-model="item.value"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col md="1" sm="6">
                    <b-button
                      block
                      variant="outline-danger"
                      class="btn-icon mt-2"
                      @click="removeItem(index)"
                      v-b-tooltip.hover.v-danger
                      v-b-tooltip.hover.bottom="lang('t_deleteFilter')"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-col>
                  <div>
                    <hr />
                  </div>
                </b-row>
              </b-form>
            </div>
            <!-- <b-button block variant="primary" @click="repeateAgain">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{ lang("t_addFilter") }}</span>
            </b-button>
            <b-button block variant="danger" @click="currentData.filter = []">
              <feather-icon icon="Trash2Icon" class="mr-25" />
              <span>{{ lang("t_clearFilter") }}</span>
            </b-button> -->
          </div>

          <!-- Form Actions -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBTooltip,
  BFormRadio,
  BSpinner,

} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    // UsersListFilters,
    BSpinner,
    flatPickr,
    BForm,
    BFormGroup,
    BFormRadio,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      isEdit: false,
      currentData: {
        _id: "",
        project: "",
        internal_name: "",
        display_name: "",
        mode: "dialer",
        status: true,
        auto_recycle: false,
        filter: [],
      },
      trHeight: null,
      queues: [],
      finish_codes: [],
      records: [],
      tmp_columns: [],
      columns: [],
      projects: [],
      calling_modes: [
        { key: "ivn", value: "IVN" },
        { key: "dialer", value: "Dialer" },
      ],
      modal_record_details: false,
      tableColumns: [
        {
          key: "campaign_status",
          label: globalThis._lang("t_status"),
          sortable: true,
        },
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "project",
          label: globalThis._lang("t_project"),
          sortable: true,
        },
        {
          key: "create_date",
          label: globalThis._lang("t_date"),
          sortable: true,
        },
        { key: "filter", label: globalThis._lang("t_filters"), sortable: true },
        {
          key: "actions",
          label: globalThis._lang("t_action"),
          sortable: false,
        },
      ],
      column_labels: {
        status: "Durum",
        assigned_agent: "Agent",
        attempts: "Temas",
        campaign: "Kampanya",
        finish_code: "Son Sonuç Kodu",
        insert_date: "Arama Tarihi",
        action_date: "Aranacak Tarih",
        action_time: "Aranacak Zaman",
        create_date: "Yüklenme Tarihi",
        create_time: "Yüklenme Zamanı",
        ex_agent: "Ex Agent",
        assign_date: "Atama Tarihi",
        total_attempts: "Toplam Temas",
      },
      operands: [
        { internal_name: "=", display_name: globalThis._lang("t_equal") },
        { internal_name: "!=", display_name: globalThis._lang("t_notEqual") },
        { internal_name: "<", display_name: globalThis._lang("t_lessThan") },
        { internal_name: ">", display_name: globalThis._lang("t_greaterThan") },
        {
          internal_name: "<=",
          display_name: globalThis._lang("t_lessThanOrEqual"),
        },
        {
          internal_name: ">=",
          display_name: globalThis._lang("t_moreThanOrEqual"),
        },
      ],
      interval: {},
    };
  },
  mounted: function () {
    this.queues = globalThis.queues;
    this.finish_codes = globalThis.finish_codes;
    this.initTrHeight();
    this.get_records();
    // this.get_queues();
    this.get_project();
    let _self = this;

    this.interval = setInterval(async () => {
      await _self.get_campaign_status();
    }, 1000);
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  beforeDestroy: function () {
    clearInterval(this.interval);
  },
  methods: {
    StartCampaign: async function (item) {
      await this.$http_in.get(`/dlr/v1/StartCampaign/${item.internal_name}`);
      await this.get_campaign_status();
    },
    StopCampaign: async function (item) {
      await this.$http_in.get(`/dlr/v1/StopCampaign/${item.internal_name}`);
      let _cmp = this.records.find(e => e.internal_name == item.internal_name);
      _cmp.campaign_status = 'stopped';
      await this.get_campaign_status();
    },
    RecycleCampaign: async function (item) {
      await this.StopCampaign(item);
      await this.$http_in.get(`/dlr/v1/RecycleCampaign/${item.internal_name}`);
      await this.get_campaign_status();
    },
    get_campaign_status: async function () {
      var response = (await this.$http_in.get(`/dlr/v1/RunningCampaigns`)).data;
      console.log("Campaign", response.RunningCampaigns);

      for (const campaign of this.records) {
        if (response.RunningCampaigns.includes(campaign.internal_name)) {
          campaign.campaign_status = 'active';
        } else {
          campaign.campaign_status = 'stopped';
        }
      }

      this.records = [...this.records];
    },
    trAddHeight(val) {
      // Add height to existing height
      // Usage: Where new element is append or more height is added (e.g. list append)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(
        this.trHeight.substring(0, this.trHeight.length - 2)
      );
      this.trHeight = `${heightValue + Number(val)}px`;
    },
    trTrimHeight(val) {
      // Remove height from existing height
      // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(
        this.trHeight.substring(0, this.trHeight.length - 2)
      );
      this.trHeight = `${heightValue - Number(val)}px`;
    },
    trSetHeight(val) {
      // Set height
      // Usage: Mostly for assigning initial value from mounted hook

      /* Assumes:
       - Height is not assigned and what to assign for add/remove operation
       - What to set height at something for odd usage
       - Incoming value is valid number in `Number` or `String`
      */
      if (val === null) this.trHeight = "auto";
      else this.trHeight = `${Number(val)}px`;
    },
    get_columns: async function () {
      this.tmp_columns = [];
      this.columns = [];
      console.log("currentData.project", this.currentData.project);
      var response = (
        await this.$http_in.get(
          `spv/v1/ProjectColumns/${this.currentData.project}_customers`
        )
      ).data;
      console.log("SmsColumns", response);
      //this.columns = response;
      this.tmp_columns =
        response[0].allkeys.filter(
          (e) =>
            ![
              "active",
              "phones",
              "sms",
              "extra_data",
              "_id",
              "TCKN_masked",
              "cycles",
              "notes",
              "products",
            ].includes(e)
        ) || [];
      for (const item of this.tmp_columns) {
        this.columns.push({
          internal_name: item,
          display_name: this.column_labels.hasOwnProperty(item)
            ? this.column_labels[item]
            : item,
        });
      }
    },

    get_records: async function () {
      var response = (await this.$http_in.get(`spv/v1/Campaign`)).data;
      console.log("Campaign", response);
      this.records = response;
      await this.get_campaign_status();
    },
    get_project: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;
      this.projects = response;
    },

    check_data() {
      if (
        this.currentData.display_name == "" ||
        [null, undefined, ""].includes(this.currentData.project)
      ) {
        return true;
      }
      return false;
    },

    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      var response;
      if (!this.isEdit) {
        console.log("this.currentData", this.currentData);
        response = (
          await this.$http_in.post(`spv/v1/Campaign`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        // if (this.stop_request()) {
        response = (
          await this.$http_in.put(`spv/v1/Campaign`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;

        this.$http_in.get(
          `dlr/v1/RefreshCampaign/${this.currentData.internal_name}`
        );

        // }
      }

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    // stop_request() {
    //   this.$swal({
    //     title: "UYARI",
    //     text: `Kampanyanız şu anda aktif ise kampanya otomatik olarak durdurulacaktır, onaylıyor musunuz?`,
    //     icon: 'warning',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     closeOnClickOutside: false,
    //     showCancelButton: true,
    //     confirmButtonText: this.lang('t_yes'),
    //     cancelButtonText: this.lang('t_cancel'),
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',

    //     },
    //     buttonsStyling: false,
    //   }).then(result => {
    //     if (result.value) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   })
    // },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`spv/v1/Campaign/${this.currentData._id}`)
      ).data;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        _id: "",
        project: "",
        internal_name: "",
        display_name: "",
        mode: "dialer",
        status: true,
        auto_recycle: false,
        filter: [],
      };
    },
    repeateAgain() {
      this.currentData.filter.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.currentData.filter.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => { });
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
      this.get_columns();
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const {
      refFormObserver,

      resetForm,
    } = formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      projects,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,

      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
