<template>
  <b-container fluid class="px-0">
    <!-- <b-custom-spinner :is_progress="is_progress"></b-custom-spinner> -->

    <b-card class="mb-1 px-0 mx-0">
      <b-row class="mx-0 px-0">
        <b-col cols="4" sm="8" md="4">
          <v-select
            v-model="selected_campaign"
            @input="update_settings"
            :options="campaigns"
            :placeholder="lang('t_campaign')"
            :reduce="(option) => option"
            label="display_name"
          >
          </v-select>
        </b-col>
        <b-col sm="4" md="8">
          <b-button
            @click="
              changeCampaignStatus(
                selected_campaign.status == 'running' ? 'stopped' : 'running'
              )
            "
            :disabled="
              selected_campaign.status == '' ||
              selected_campaign.status == 'recycling'
            "
            variant="primary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon
              :icon="
                selected_campaign.status == 'running' ? 'PauseIcon' : 'PlayIcon'
              "
            />
          </b-button>
          <b-button
            @click="changeCampaignStatus('recycling')"
            :disabled="
              selected_campaign.status == '' ||
              selected_campaign.status == 'running' ||
              selected_campaign.status == 'recycling'
            "
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
          <b-button
            v-if="
              other_permission.includes('dialer_reset_schedule_calls') &&
              selected_campaign.hasOwnProperty('internal_name')
            "
            v-b-tooltip.hover.v-warning
            v-b-tooltip.hover.bottom="lang('t_resetScheduledCalls')"
            @click="clearSchCallTimes"
            variant="warning"
            class="btn-icon rounded-circle float-right ml-50"
          >
            <feather-icon icon="ClockIcon" />
          </b-button>
          <b-button
            v-if="
              other_permission.includes('dialer_reset_daily_attempts') &&
              selected_campaign.hasOwnProperty('internal_name')
            "
            v-b-tooltip.hover.v-warning
            v-b-tooltip.hover.bottom="lang('t_resetDailyAttempts')"
            @click="clearDailyAttempts"
            variant="warning"
            class="btn-icon rounded-circle float-right ml-50"
          >
            <feather-icon icon="ShieldOffIcon" />
          </b-button>
          <b-button
            v-if="
              other_permission.includes('dialer_reset_total_attempts') &&
              selected_campaign.hasOwnProperty('internal_name')
            "
            v-b-tooltip.hover.v-warning
            v-b-tooltip.hover.bottom="lang('t_resetTotalAttempts')"
            @click="clearTotalAttempts"
            variant="warning"
            class="btn-icon rounded-circle float-right ml-50"
          >
            <feather-icon icon="UnlockIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="mx-0 px-0">
      <b-col sm="12" md="4">
        <b-card style="height: 25vh" class="card-browser-states mt-1" no-body>
          <!-- <b-card-header class="pa-0">
            <div>
              <b-card-title>Browser States</b-card-title>
            </div>
          </b-card-header> -->

          <!-- body -->
          <b-card-body>
            <div
              v-for="(item, index) in agent_data"
              :key="index"
              class="browser-states mt-50"
            >
              <b-media no-body>
                <feather-icon class="mr-1" icon="SlackIcon" />
                <h6 class="align-self-center my-auto">
                  {{ item.key }}
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <span
                  class="font-weight-bold text-body-heading mr-1"
                  style="
                    display: inline-block;
                    width: 7vw;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                  "
                  >{{ item.value }}</span
                >
              </div>
            </div>
          </b-card-body>
          <!--/ body -->
        </b-card>
      </b-col>
      <b-col sm="12" md="4">
        <b-card style="height: 25vh" class="card-browser-states mt-1" no-body>
          <!-- <b-card-header class="pa-0">
            <div>
              <b-card-title>Browser States</b-card-title>
            </div>
          </b-card-header> -->

          <!-- body -->
          <b-card-body>
            <div
              v-for="(item, index) in campaign_data"
              :key="index"
              class="browser-states mt-50"
            >
              <b-media no-body>
                <feather-icon class="mr-1" icon="SlackIcon" />
                <h6 class="align-self-center my-auto">
                  {{ item.key }}
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <span
                  class="font-weight-bold text-body-heading mr-1"
                  style="
                    display: inline-block;
                    width: 7vw;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                  "
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="item.value"
                  >{{ item.value }}</span
                >
              </div>
            </div>
          </b-card-body>
          <!--/ body -->
        </b-card>
      </b-col>
      <b-col sm="12" md="4">
        <b-card style="height: 25vh" class="card-browser-states mt-1" no-body>
          <!-- <b-card-header class="pa-0">
            <div>
              <b-card-title>Browser States</b-card-title>
            </div>
          </b-card-header> -->

          <!-- body -->
          <b-card-body>
            <div
              v-for="(item, index) in call_data"
              :key="index"
              class="browser-states mt-50"
            >
              <b-media no-body>
                <feather-icon class="mr-1" icon="SlackIcon" />
                <h6 class="align-self-center my-auto">
                  {{ item.key }}
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <b-badge
                  variant="primary"
                  class="badge-glow text-center"
                  style="width: 5em"
                >
                  <span
                    class="font-weight-bold text-body-heading text-white m-auto"
                    >{{ item.value }}</span
                  >
                </b-badge>
              </div>
            </div>
          </b-card-body>
          <!--/ body -->
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col sm="12" md="5">
        <b-card style="height: 36vh; overflow-y: auto" no-body>
          <!-- <chartjs-component-bar-chart
            :height="300"
            :data="chartjsData.latestBarChart.data"
            :options="chartjsData.latestBarChart.options"
            :key="custom_key"
          /> -->
          <b-table
            style="min-height: 26vh; overflow-y: auto"
            small
            :fields="fc_fields"
            :items="fc_data"
            responsive="sm"
          >
            <template #cell(rate)="data">
              <b-progress
                :value="data.value"
                :max="total_finish_code_counts"
                :variant="
                  parseInt((data.value / total_finish_code_counts) * 100) > 40
                    ? 'success'
                    : 'warning'
                "
                striped
              />
            </template>

            <template #cell(category)="data">
              <b-badge pill :variant="fc_category_colors[data.value]">
                {{ data.value }}
              </b-badge>
            </template>
          </b-table>
          <b-row class="border-top float-bottom mx-0">
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_totalCallInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_total")
                }}</span>
                <h6>{{ call_summary.TotalCall }}</h6>
              </center>
            </b-col>
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_spokenCallInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_spokenCall")
                }}</span>
                <h6>{{ call_summary.SpokenCall }}</h6>
              </center>
            </b-col>
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_spokenRateInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_spokenCallRate")
                }}</span>
                <h6>{{ call_summary.SpokenRate }}</h6>
              </center>
            </b-col>
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_noSpokenCallInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_nonSpokenCall")
                }}</span>
                <h6>{{ call_summary.NoSpokenCall }}</h6>
              </center>
            </b-col>

            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_noAttemptsCallInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_nAttemptsCall")
                }}</span>
                <h6>{{ call_summary.NoAttemptsCall }}</h6>
              </center>
            </b-col>
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_attemptsRateInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_attemptsRate")
                }}</span>
                <h6>{{ call_summary.AttemptsRate }}</h6>
              </center>
            </b-col>

            <b-col
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_reachRateInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_reachRate")
                }}</span>
                <h6>{{ call_summary.ReachRate }}</h6>
              </center>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="12" md="7">
        <b-card style="height: 36vh; overflow-y: auto" no-body>
          <b-table
            small
            :items="active_calls"
            :fields="active_call_fields"
            striped
            responsive="sm"
          >
            <template #cell(Status)="data">
              <feather-icon
                :icon="state_labels[data.value].icon"
                :class="state_labels[data.value].color"
              ></feather-icon>
              {{ state_labels[data.value].display_name }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  VBTooltip,
  BProgress,
  BTable,
  BContainer,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BImg,
  BButton,
  BCol,
  BRow,
  BCard,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { $themeColors } from "@themeConfig";
import ChartjsComponentBarChart from "./ChartjsComponentBarChart.vue";
import chartjsData from "./chartjsData";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BProgress,
    BTable,
    BContainer,
    BBadge,
    ChartjsComponentBarChart,
    vSelect,
    BButton,
    BCol,
    BRow,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BImg,
    VBTooltip,
  },
  props: {
    getCampaignDetails: Function,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      socket: {},
      selected_project_name: "",
      selected_campaign: {
        status: "",
      },
      other_permission: [],

      selectable_columns: [],
      unselectable_columns: [],
      tmp_potential_columns: [],
      tmp_unpotential_columns: [],
      campaigns: [],
      projects: [],
      tc: [],
      ivr: [],
      lg: [],
      lines: [],
      columns: [],
      agent_data: [],
      custom_key: 123,
      call_summary_interval: "",
      chart_interval: "",
      campaign_data: [
        {
          key: globalThis._lang("t_campaignStatus"),
          value: "-",
        },
        {
          key: globalThis._lang("t_campaignTotalPhonesToCall"),
          value: "-",
        },
        {
          key: globalThis._lang("t_campaignCallPerAgent"),
          value: "-",
        },
        {
          key: globalThis._lang("t_campaignAbandonRate"),
          value: "-",
        },

        {
          key: globalThis._lang("t_campaignFilterCriteria"),
          value: "-",
        },
        {
          key: globalThis._lang("t_campaignSortingCriteria"),
          value: "-",
        },
        {
          key: globalThis._lang("t_campaignRemainingCycles"),
          value: "-",
        },
      ],
      call_data: [
        {
          key: globalThis._lang("t_campaignRemainingPhonesInCycle"),
          value: "-",
        },
        {
          key: globalThis._lang("t_scheduledCalls"),
          value: "-",
        },
        {
          key: globalThis._lang("t_closedByDailyAttempts"),
          value: "-",
        },
        {
          key: globalThis._lang("t_closedByTotalAttempts"),
          value: "-",
        },
        // {
        //   key: "Çağrı Sayısı / Saat",
        //   value: "-",
        // },
        {
          key: globalThis._lang("t_campaignTotalPhonesCalled"),
          value: "-",
        },
        {
          key: globalThis._lang("t_campaignConnectedCall"),
          value: "-",
        },
        {
          key: globalThis._lang("t_campaignWaitingInQueue"),
          value: "-",
        },
        // {
        //   key: globalThis._lang("t_campaignAbandonRate"),
        //   value: '-',
        // },
      ],
      active_calls: [],
      active_call_fields: [
        {
          key: "RemoteNumber",
          label: globalThis._lang("t_number"),
          sortable: true,
        },
        { key: "Status", label: globalThis._lang("t_status"), sortable: true },
        { key: "Line", label: globalThis._lang("t_line"), sortable: true },
        { key: "Agent", label: globalThis._lang("t_agent"), sortable: true },
        {
          key: "CustomerNumber",
          label: globalThis._lang("t_customerId"),
          sortable: true,
        },
        {
          key: "CustomerName",
          label: globalThis._lang("t_nameSurname"),
          sortable: true,
        },
      ],
      fc_fields: [
        {
          key: "finish_code",
          label: globalThis._lang("t_finishCode"),
          sortable: true,
        },
        { key: "count", label: globalThis._lang("t_count"), sortable: true },
        { key: "rate", label: globalThis._lang("t_rate"), sortable: true },
        {
          key: "category",
          label: globalThis._lang("t_category"),
          sortable: true,
        },
      ],
      fc_data: [],
      call_summary: {
        TotalCall: 0,
        SpokenCall: 0,
        SpokenRate: 0,
        NoSpokenCall: 0,
        NoAttemptsCall: 0,
        AttemptsRate: 0,
        ReachRate: 0,
      },
      fc_category_colors: {
        Başarılı: "success",
        Başarısız: "warning",
        Randevu: "primary",
        Diğer: "secondary",
        Sistem: "secondary",
      },
      state_labels: {
        CallPlaced: {
          display_name: globalThis._lang("t_callPlaced"),
          icon: "PhoneIcon",
          color: "text-warning",
        },
        CallWentToAgent: {
          display_name: globalThis._lang("t_callWentToAgent"),
          icon: "PhoneForwardedIcon",
          color: "text-primary",
        },
        "CallJoinedQueue": {
          display_name: globalThis._lang("t_callJoinedQueue"),
          icon: "PhoneForwardedIcon",
          color: "text-primary",
        },
        CallPickedUp: {
          display_name: globalThis._lang("t_callPickedUp"),
          icon: "PhoneCallIcon",
          color: "text-success",
        },
        CallRejected: {
          display_name: globalThis._lang("t_callRejected"),
          icon: "PhoneOffIcon",
          color: "text-danger",
        },
        NoRouteToDestination: {
          display_name: globalThis._lang("t_noRouteToDestination"),
          icon: "PhoneOffIcon",
          color: "text-danger",
        },
      },
      total_finish_code_counts: 0,
      column_labels: {
        NoAnswer: "s_Cevapsız",
        Busy: "s_Meşgul",
        CallRejected: "s_Reddedildi",
        FaultyCall: "s_HatalıArama",
        NetworkOutOfOrder: "s_NetworkHatası",
        FateUnknown: "s_Bilinmiyor",
        ConnectivityError: "s_BağlantıHatası",
        FaultyNumber: "s_HatalıNumara",
        AnsweringMachine: "s_Makine",
        NoRouteToDestination: "s_OperatördenÇıkmadı",
        ProblemEstablishingCall: "s_ÇağrıKurulamadı",
        Unreachable: "s_ Kapalı/Erişilmez",
      },
      customer_column_labels: {
        status: globalThis._lang("t_customersStatus"),
        assigned_agent: globalThis._lang("t_assigned_agent"),
        attempts: globalThis._lang("t_attempts"),
        campaign: globalThis._lang("t_campaign"),
        finish_code: globalThis._lang("t_finish_code"),
        insert_date: globalThis._lang("t_insert_date"),
        action_date: globalThis._lang("t_action_date"),
        create_date: globalThis._lang("t_create_date"),
        ex_agent: globalThis._lang("t_ex_agent"),
        assign_date: globalThis._lang("t_assign_date"),
      },
      settings: {},

      chartjsData,

      chartData: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      browserData: [
        {
          browserImg: require("@/assets/images/icons/google-chrome.png"),
          name: "Google Chrome",
          usage: "-",
        },
        {
          browserImg: require("@/assets/images/icons/mozila-firefox.png"),
          name: "Mozila Firefox",
          usage: "-",
        },
        {
          browserImg: require("@/assets/images/icons/apple-safari.png"),
          name: "Apple Safari",
          usage: "-",
        },
        {
          browserImg: require("@/assets/images/icons/internet-explorer.png"),
          name: "Internet Explorer",
          usage: "-",
        },
        {
          browserImg: require("@/assets/images/icons/opera.png"),
          name: "Opera Mini",
          usage: "-",
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  methods: {

    clearSchCallTimes() {
      this.$swal({
        title: globalThis._lang("t_warning"),
        text: `Zamanı gelmeyen çağrılar sıfırlanacak, onaylıyor musunuz?`,
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.socket.emit("CampaignDeScheduleAllCalls", {
          Campaign: this.selected_campaign.internal_name,
        });
      });
    },
    clearDailyAttempts() {
      this.$swal({
        title: globalThis._lang("t_warning"),
        text: `Günlük temastan kapanan datalar açılacak. Bunun için kampanya ayarlarından günlük teması arttırmanız gerekiyor. Bu işlem geri alınamaz. Onaylıyor musunuz?`,
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.socket.emit("CampaignResetDailyAttempts", {
          Campaign: this.selected_campaign.internal_name,
        });
        this.$bvToast.toast(
          `Kampaya ayarlarından günlük teması arttırmanız gerekiyor. Aksi halde aramalar başlamayacaktır.`,
          {
            title: `${globalThis._lang("t_info")}`,
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          }
        );
      });
    },
    clearTotalAttempts() {
      this.$swal({
        title: globalThis._lang("t_warning"),
        text: `Toplam temastan kapanan datalar açılacak. Bunun için kampanya ayarlarından toplam teması arttırmanız gerekiyor. Bu işlem geri alınamaz. Onaylıyor musunuz?`,
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.socket.emit("CampaignResetTotalAttempts", {
          Campaign: this.selected_campaign.internal_name,
        });
        this.$bvToast.toast(
          `Kampaya ayarlarından toplam teması arttırmanız gerekiyor. Aksi halde aramalar başlamayacaktır.`,
          {
            title: `${globalThis._lang("t_info")}`,
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          }
        );
      });
    },
    changeCampaignStatus: async function (status) {
      this.socket.emit("CampaignControl", {
        Campaign: this.selected_campaign.internal_name,
        Status: status,
      });
    },
    get_campaign_finish_codes: async function () {
      var response = (
        await this.$http_in.get(
          `spv/v1/DialerSummary/${this.selected_campaign.internal_name}`
        )
      ).data;

      this.fc_data = [];
      this.total_finish_code_counts = 0;
      for (const item of response) {
        this.total_finish_code_counts += item.count;
      }

      for (const item of response) {
        // console.log("globalThis.finish_codes.find(j => j.display_name == item)",globalThis.finish_codes.find(j => j.finish_code == item) );
        let _fc = globalThis.finish_codes.find(
          (j) => j.finish_code == item._id
        );
        let _category = _fc
          ? globalThis.finish_categories.find((e) => e._id == _fc.category_id)
          : undefined;
        this.fc_data.push({
          finish_code: this.column_labels.hasOwnProperty(item._id)
            ? this.column_labels[item._id]
            : item._id,
          count: item.count,
          rate: item.count,
          category: _category
            ? globalThis.finish_categories.find((e) => e._id == _fc.category_id)
              .category
            : "Sistem",
        });
      }

      // chartjsData.latestBarChart.data.labels = response.map(e => { return this.column_labels.hasOwnProperty(e._id) ? this.column_labels[e._id] : e._id });
      // chartjsData.latestBarChart.data.datasets[0].data = response.map(e => e.count);
      // this.custom_key = Math.floor(Math.random() * 101);
    },
    get_call_summary: async function () {
      var response = (
        await this.$http_in.get(
          `spv/v1/DialerCallSummary/${this.selected_campaign.internal_name}`
        )
      ).data;

      this.call_summary = {
        TotalCall: 0,
        SpokenCall: 0,
        SpokenRate: 0,
        NoSpokenCall: 0,
        NoAttemptsCall: 0,
        AttemptsRate: 0,
        ReachRate: 0,
      };

      // for (const item of response) {
      //   if (item._id == 'ANSWERED') {
      //     this.call_summary.success = item.count;
      //   } else {
      //     this.call_summary.fail = item.count;
      //   }
      // }
      // this.call_summary.total = this.call_summary.success + this.call_summary.fail;
      // this.call_summary.rate = Math.round(this.call_summary.success / this.call_summary.total * 100) || 0;

      this.call_summary = {
        ...response,
        SpokenRate: response.SpokenRate ?? 0,
        ReachRate: response.ReachRate ?? 0,
        AttemptsRate: response.AttemptsRate ?? 0,
      };
    },
    update_settings(item) {
      var _self = this;
      console.log("item", item);

      this.get_campaign_finish_codes();
      this.get_call_summary();
      if (this.chart_interval != "") {
        clearInterval(this.chart_interval);
      }
      if (this.call_summary_interval != "") {
        clearInterval(this.call_summary_interval);
      }
      this.chart_interval = setInterval(function () {
        _self.get_campaign_finish_codes();
      }, parseInt(item.settings.statistics_period) * 60 * 1000);
      this.call_summary_interval = setInterval(function () {
        _self.get_call_summary();
      }, 1 * 60 * 1000);

      this.selected_project_name = this.projects.find(
        (e) => e.internal_name == this.selected_campaign.project
      ).display_name;
      // console.log('this.projects.find(e=> e.internal_name = this.selected_campaign.project)', this.projects.find(e=> e.internal_name = this.selected_campaign.project));

      this.socket.emit("RegisterToCampaignInfo", {
        Campaign: this.selected_campaign,
      });

      if (
        this.selected_campaign.settings != undefined ||
        this.selected_campaign.settings != null
      ) {
        this.settings = { ...this.selected_campaign.settings };
      } else {
        this.settings = {
          priortiy: "100",
          max_call: "200",
          call_per_agent: "3",
          statistics_period: "1",
          line_group: "dialplan",
          timeout_second: "30",
          abandon_rate: "",
          target: "",
          machine_detection: "",
          sort_criteria: [],
          max_recyle: "1",
          next_call_time: "0",
          after_call_statu: `${globalThis._lang("t_afterCall")}`,
          missed_call_abandon_time: "30",
          max_phone_touch: "5",
          max_contact_touch: "",
          max_day_touch: "",
          day_object_effect: "",
          missed_call_auto_sch: "",
          busy_call_auto_sch: "",
          system_hangup_call_auto_sch: "",
          remote_hangup_call_auto_sch: "",
          machine_call_auto_sch: "",
          fax_call_auto_sch: "",
          time_condition: "",
          live_contact: {
            category: "",
            target: "",
          },
          no_idle_agent: {
            category: "",
            target: "",
          },
          machine: {
            category: "",
            target: "",
          },
          fax: {
            category: "",
            target: "",
          },
          rules: [],
          max_phone_total_attempts: "12",
        };
      }

      var queue = {
        internal_name: "",
        display_name: "",
      };
      console.log(
        "this.settings.live_contact.target",
        this.settings.live_contact.target
      );

      if (this.settings.live_contact.target != "") {
        var _ivr = this.ivr.find(
          (e) => e.internal_name === this.settings.live_contact.target
        );
        console.log("_ivr", _ivr);
        if (_ivr.ivr_object[0].name == "queue") {
          queue["internal_name"] = _ivr.ivr_object[0].target.internal_name;
          queue["display_name"] = _ivr.ivr_object[0].target.display_name;
        }
      }

      this.settings.queue = queue.internal_name;
      this.set_current_queue();
      this.getCampaignDetails(this.selected_campaign);
    },
    set_current_queue() {
      var queue = {
        internal_name: "",
        display_name: "",
      };
      console.log(
        "this.settings.live_contact.target",
        this.settings.live_contact.target
      );

      if (this.settings.live_contact.target != "") {
        var _ivr = this.ivr.find(
          (e) => e.internal_name === this.settings.live_contact.target
        );
        console.log("_ivr", _ivr);
        // if (_ivr.ivr_object[0].name == 'queue') {
        //   queue['internal_name'] = _ivr.ivr_object[0].target.internal_name;
        //   queue['display_name'] = _ivr.ivr_object[0].target.display_name;
        // }
        // else if (_ivr.ivr_object[0].name == 'menu') {
        //   for (const item of _ivr.ivr_object[0].tasks) {
        //     if (item.name == 'queue') {
        //       queue['internal_name'] = item.target.internal_name;
        //       queue['display_name'] = item.target.display_name;
        //       break;
        //     }
        //   }
        // }
      }
      console.log("queue", queue);
      this.settings.queue = queue.internal_name;
      this.settings.queue_display_name = queue.display_name;

      this.settings.trunk = this.settings.line_group.internal_name;
      this.settings.trunk_prefix = this.settings.line_group.prefix;
      this.settings.caller_id = this.settings.line_group.caller_number;
    },
    getStatusName(status) {
      var durum = "";
      switch (status) {
        case "running":
          durum = globalThis._lang("t_started");
          break;
        case "stopped":
          durum = globalThis._lang("t_stopped");
          break;
        case "recycling":
          durum = globalThis._lang("t_recycling");
          break;
        case "completed":
          durum = globalThis._lang("t_isCompleted");
          break;
        default:
          durum = "Durduruldu";
          break;
      }
      return durum;
    },
    get_campaign: async function () {
      var response = (await this.$http_in.get(`spv/v1/Campaign`)).data;

      this.campaigns = response;
    },
    get_projects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    get_IvrManagementTargets: async function () {
      var response = (await this.$http_in.get(`spv/v1/IvrManagementTargets`))
        .data;

      this.tc = response.TimeConditions;
      this.ivr = response.Ivr;
    },
    get_TimeConditions: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/TimeCondition`, {
          headers: {
            authorization: globalThis.token,
          },
        })
      ).data;

      this.tc = response;
    },
    get_LineGroup: async function () {
      var response = (await this.$http_in.get(`spv/v1/LineGroup`)).data;

      this.lg = response;
      console.log("asd", response);
      // this.lg.unshift({
      //   internal_name: 'dialplan',
      //   display_name: 'Arama Planı',
      // });
    },
    get_line: async function () {
      var response = (await this.$http_in.get(`spv/v1/Line`)).data;

      this.lines = response;
    },
  },
  mounted: async function () {
    this.other_permission = globalThis.permissions["other"] || [];

    this.socket = new this.$ws(
      `${globalThis.env["PROTOCOL"]}://${globalThis.env["DIALER_URL"]}`,
      {
        forceNew: true,
        extraHeaders: {
          Authorization: `Bearer ${globalThis.qm_token}`,
        },
      }
    );
    this.socket.on("connect", () => {
      console.log("DIALER CONNECT");

      this.socket.on("CampaignUpdate", (msg) => {
        console.log("CampaignUpdate", msg);
        if (![undefined, null].includes(msg)) {
          if (msg.Name === this.selected_campaign.internal_name) {
            this.agent_data = [];

            this.agent_data.push({
              key: globalThis._lang("t_project"),
              value: this.selected_project_name,
            });
            this.agent_data.push({
              key: globalThis._lang("t_queue"),
              value: msg.QueueDisplayName,
            });

            this.agent_data.push({
              key: globalThis._lang("t_total"),
              value: msg.TotalAgentCount,
            });
            this.agent_data.push({
              key: globalThis._lang("t_available"),
              value: msg.AvailableAgentCount,
            });

            this.active_calls = msg.CurrentCallInfo;

            var _filter = "";
            if (
              !["", null, undefined].includes(this.selected_campaign.filter)
            ) {
              this.selected_campaign.filter.map((e) => {
                _filter += e.column + e.operand + e.value + ",";
              });
            }

            var _sort_criteria = "";
            if (
              !["", null, undefined].includes(this.selected_campaign.filter)
            ) {
              this.tmp_unpotential_columns.map((e) => {
                console.log(e);
                _sort_criteria += e.replace("##", "_") + ",";
              });
            }

            this.selected_campaign.status = msg.RunningStatus;
            this.campaign_data[0].value = this.getStatusName(msg.RunningStatus);

            this.campaign_data[1].value = msg.TotalCalls;
            this.campaign_data[2].value = msg.CallPerAgent;
            this.campaign_data[3].value = msg.AbandonRate;
            // this.campaign_data[3].value = msg.Priority;
            this.campaign_data[4].value = _filter.substr(0, _filter.length - 1);
            this.campaign_data[5].value = _sort_criteria.substr(
              0,
              _sort_criteria.length - 1
            );
            this.campaign_data[6].value = msg.RemainingCycles;

            this.call_data[0].value = msg.CurrentCycleCalls;
            this.call_data[1].value = msg.ScheduledCallCount ?? 0;

            // this.call_data[3].value = msg.CallsPerHour;
            this.call_data[2].value = msg.ClosedByDailyAttempts ?? 0;
            this.call_data[3].value = msg.ClosedByTotalAttempts ?? 0;
            // this.call_data[3].value = Number(msg.TotalCalls) - Number(msg.CurrentCycleCalls);
            this.call_data[4].value = msg.CurrentRingingCalls;
            this.call_data[5].value = msg.ConnectedCalls;
            this.call_data[6].value = msg.CallsWaitingInQueue;
          }
        }
      });
    });
    this.is_progress = true;

    await this.get_campaign();
    await this.get_projects();
    await this.get_TimeConditions();
    await this.get_IvrManagementTargets();
    // await this.get_line();
    await this.get_LineGroup();

    // const this_data = JSON.parse(localStorage.getItem("dialer_cache"));

    // if (![undefined, null].includes(this_data)) {
    //   this.selected_campaign = this_data.selected_campaign;
    //   this.update_settings(this.selected_campaign);
    // }
    this.is_progress = false;
  },
  beforeDestroy: function () {
    this.socket.disconnect();
    if (this.chart_interval != "") {
      clearInterval(this.chart_interval);
    }
    if (this.call_summary_interval != "") {
      clearInterval(this.call_summary_interval);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
